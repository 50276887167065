<div class="slide-wrapper" id="abc1">

    <div id="homepage-feature" class="carousel slide abc1" data-ride="carousel">
        <ol class="carousel-indicators">
            <li data-target="#homepage-feature" data-slide-to="0" class="active"></li>
            <li data-target="#homepage-feature" data-slide-to="1"></li>
            <li data-target="#homepage-feature" data-slide-to="2"></li>
            <li data-target="#homepage-feature" data-slide-to="3"></li>
            <li data-target="#homepage-feature" data-slide-to="4"></li>
            <li data-target="#homepage-feature" data-slide-to="5"></li>
            <!-- <li data-target="#homepage-feature" data-slide-to="5"></li>
            <li data-target="#homepage-feature" data-slide-to="6"></li> -->
            <!-- <li data-target="#homepage-feature" data-slide-to="4"></li> -->
        </ol>

        <div class="carousel-inner">

            <!-- <div class="item active"
                style="background-image:url(./../../../assets/carousel/slider/carousel-IndependenceDay.png)">
                <div class="carousel-caption">
                    <div class="caption animated fadeIn carousel-shadow">
                        <h2 class="animated fadeInLeft Empowering-Growth-En">{{'Empowering' | translate}} <span
                                class="text-style-1">{{'Growth' | translate}}</span></h2>
                        <h2 class="animated fadeInRight Empowering-Growth-En">{{'Enriching' | translate}} <span
                                class="text-style-1">{{'Environment' | translate}}</span></h2>
                    </div>
                </div>
            </div> -->
            <!-- <a class="carousel-item item active "
                style="background-image:url(./../../../assets/carousel/slider/gailgascivilbaner.PNG);background-size: 100% 100%;z-index: 2;"
                *ngIf="this.language=='en'" id="mohan" target="_blank">
            </a>
            <a class="carousel-item item active "
                style="background-image:url(./../../../assets/carousel/slider/gailgascivilbaner.PNG);background-size: 100% 100%;z-index: 2;"
                *ngIf="this.language=='hn'" id="mohan" target="_blank">
            </a> -->
            <!-- <a class="carousel-item item  "
                style="background-image:url(./../../../assets/carousel/slider/Gail_banner_1.jpg);background-size: 100% 100%;z-index: 2;"
                *ngIf="this.language=='en'" id="mohan" target="_blank">
            </a>
            <a class="carousel-item item "
                style="background-image:url(./../../../assets/carousel/slider/Gail_banner_1.jpg);background-size: 100% 100%;z-index: 2;"
                *ngIf="this.language=='hn'" target="_blank">
            </a> -->


            <!-- <a class="carousel-item item  active"
                style="background-image:url(./../../../assets/carousel/slider/new_eng.png);background-size: 100% 100%;z-index: 3;"
                *ngIf="this.language=='en'" id="mohan" target="_blank">
            </a> -->
            <a class="carousel-item item  active"
                style="background-image:url(./../../../assets/carousel/slider/Homeheader.jpg);background-size: 100% 100%; object-fit:fill; z-index: 3;"
                *ngIf="this.language=='en'" id="mohan" target="_blank">
            </a>
            <!-- <a class="carousel-item item active"
                style="background-image:url(./../../../assets/carousel/slider/new_hindi.png);background-size: 100% 100%;z-index: 3;"
                *ngIf="this.language=='hn'" target="_blank">
            </a> -->
            <a class="carousel-item item active"
                style="background-image:url(./../../../assets/carousel/slider/Homeheader.jpg);background-size: 100% 100%;z-index: 3;"
                *ngIf="this.language=='hn'" target="_blank">
            </a>

            <div class="carousel-item item" style="z-index:0" 
                style="background-image:url(./../../../assets/carousel/slider/carousel-gail-four.png);background-size: 100% 100%;">
                <!-- <div class="carousel-caption" style="z-index: 0;">
                    <div class="caption animated fadeIn carousel-shadow">
                        <h2 class="animated fadeInLeft Empowering-Growth-En">{{'Empowering' | translate}} <span
                                class="text-style-1">{{'Growth' | translate}}</span></h2>
                        <h2 class="animated fadeInRight Empowering-Growth-En">{{'Enriching' | translate}} <span
                                class="text-style-1">{{'Environment' | translate}}</span></h2>
                    </div>
                </div> -->
            </div>

            <div class="carousel-item item"
                style="background-image:url(./../../../assets/carousel/slider/carousel-gail-two.png);background-size: 100% 100%;z-index: -2;">
                <div class="carousel-caption">
                    <!-- <div class="caption animated fadeIn carousel-shadow">
                        <h2 class="animated fadeInLeft Empowering-Growth-En">{{'surpassEmpowering' | translate}}& <span
                                class="text-style-1"></span></h2>
                        <h2 class="animated fadeInRight Empowering-Growth-En">{{'surpassEnriching' | translate}} <span
                                class="text-style-1">{{'surpassEnvironment' | translate}}</span></h2>
                    </div> -->
                </div>
            </div>

            <div class="carousel-item item"
                style="background-image:url(./../../../assets/carousel/slider/carousel-gail-three.png);background-size: 100% 100%;z-index: -2;">
                <!-- <div class="carousel-caption">
                    <div class="caption animated fadeIn carousel-shadow">
                        <h2 class="animated fadeInLeft Empowering-Growth-En">{{'eneringProgress' | translate}} <span
                                class="text-style-1"></span></h2>
                        <h2 class="animated fadeInRight Empowering-Growth-En">{{'energingWith' | translate}} <span
                                class="text-style-1">{{'greenEnergy' | translate}}</span></h2>
                    </div>
                </div> -->
            </div>

            <div class="carousel-item item"
                style="background-image:url(./../../../assets/carousel/slider/carousel-gail-one.png);background-size: 100% 100%;z-index: -2;">
                <!-- <div class="carousel-caption">
                    <div class="caption animated fadeIn carousel-shadow">
                        <h2 class="animated fadeInLeft Empowering-Growth-En">{{'marching' | translate}} <span
                                class="text-style-1"></span></h2>
                        <h2 class="animated fadeInRight Empowering-Growth-En"><span class="text-style-1">{{'GreenerFuel'
                                | translate}}</span></h2>
                    </div>
                </div> -->
            </div>

            <div class="carousel-item item"
                style="background-image:url(./../../../assets/carousel/slider/carousel-gail-six.png);background-size: 100% 100%;z-index: -2;">
                <!-- <div class="carousel-caption">
                    <div class="caption animated fadeIn carousel-shadow">
                        <h2 class="animated fadeInLeft Empowering-Growth-En">{{'marching' | translate}} <span
                                class="text-style-1"></span></h2>
                        <h2 class="animated fadeInRight Empowering-Growth-En"><span class="text-style-1">{{'GreenerFuel'
                                | translate}}</span></h2>
                    </div>
                </div> -->
            </div>

        </div>
        <!-- /.carousel-inner -->
        <!-- Controls -->
        <!-- <a class="left carousel-control" href="#homepage-feature" data-slide="prev">
            <span class="fa fa-angle-left"></span>
        </a>
        <a class="right carousel-control" href="#homepage-feature" data-slide="next">
            <span class="fa fa-angle-right"></span>
        </a> -->
        <a class="carousel-control-prev" data-target="#homepage-feature" data-slide="prev" style="cursor: pointer;">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" data-target="#homepage-feature" data-slide="next" style="cursor: pointer;">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
        </a>
    </div>
    <!-- /#homepage-feature.carousel -->
    <div class="red" style="z-index: 2;">
        <div class="red-content menu-border">
            <div (click)="sahajUrl()">
                <img src="./../../../assets/sahag-logo.svg" class="sahaj-logo" style="padding: 0px 0px 0px 25px;">
            </div>
            <div class="menu-content">
                <a href="https://customer.gailgaspng.com/Home/UploadRegistrationDocuments_BeforeLogin" target="blank"
                    style="color: #0f4a5b;text-decoration: none;">
                    <span>
                        <img src="./../../../assets/carousel/carouselMenu/finger.svg"
                            style="width: 35px; height: 35px;"> &nbsp;
                        <span> {{'apply-now' | translate}} </span>
                    </span>
                </a>

                <hr>
            </div>

            <div class="menu-content">
                <span>
                    <a href="https://customer.gailgaspng.com/" target="blank"
                        style="color: #0f4a5b;text-decoration: none;">
                        <img src="../../../assets/carousel/carouselMenu/exit.svg"> &nbsp;
                        <span>{{'my-account' | translate}} </span>
                    </a>
                </span>
                <hr>
            </div>

            <div class="menu-content">
                <span>
                    <a href="https://customer.gailgaspng.com/online/billpay" target="blank"
                        style="color: #0f4a5b;text-decoration: none;">

                        <img src="../../../assets/carousel/carouselMenu/billing-machine.svg"> &nbsp;
                        <span> {{'pay-bills' | translate}} </span>
                    </a>
                </span>
                <hr>
            </div>

            <div class="menu-content">
                <span routerLink="/faq">
                    <img src="../../../assets/carousel/carouselMenu/help.svg"> &nbsp;
                    <span> {{'faq' | translate}} </span>
                </span>
                <hr>
            </div>

            <div class="menu-content">
                <span>
                    <a href="https://gailebank.gail.co.in:4431/customer_complaint.asp" target="blank"
                        style="color: #0f4a5b;text-decoration: none;">
                        <img src="../../../assets/carousel/carouselMenu/feedback.svg"> &nbsp;
                        <span> {{'feedback' | translate}} </span>
                    </a>
                </span>
                <hr>
            </div>

            <div class="menu-content">
                <span routerLink="/submitForm">
                    <img src="../../../assets/carousel/carouselMenu/exit.svg"> &nbsp;
                    <span> {{'pratinidhi' | translate}} </span>
                </span>
                <!-- <hr> -->
            </div>

            <div class="domestic-purpose">{{'domesticPurpose' | translate}}</div>
        </div>


    </div>

</div>
<!-- Caraousel End -->

<!-- Second Fold -->

<div class="container-fluid second-fold">

    <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-10">

            <div class="container-fluid">
                <!-- <div class="centered">
        
                    <div fxLayout="row wrap" fxLayout.xs="column">
                        <div fxFlex="33%;" fxFlex.sm="35%" fxFlex.md="33%" fxFlex.xs="80%" routerLink="/calculator/savings-calculator">
                            <img src="./../../../assets/carousel/saving.svg">
                            <span style="font-family:ApexMedium;">{{'snvgCal' | translate}}</span>
                        </div>
        
                        <div fxFlex="33%;" fxFlex.sm="50%" fxFlex.md="33%" fxFlex.xs="80%" routerLink="/calculator/savings-calculator">
                            <img src="./../../../assets/carousel/carbon.svg">
                            <span style="font-family:ApexMedium;">{{'crbnftCal' | translate}}</span>
                        </div>
        
                        <div fxFlex="33%;" fxFlex.sm="15%" fxFlex.md="33%" fxFlex.xs="80%">
                            <div (click)="sahajUrl()">
                                <img src="./../../../assets/sahag-logo.svg" class="sahaj-logo">
                            </div>
                        </div>
                    </div>
                </div> -->

                <div class="row">

                    <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 col-xl-1 text-center">
                        <img src="./../../../assets/carousel/ports.svg" class="qoute-img">
                    </div>

                    <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 col-xl-10 text-center qoute-copy"
                        *ngIf="language=='en'">
                        <div class="Be-the-leading-compa">
                            {{'be' | translate}} <span class="text-style-1">{{'lc' | translate}}</span> {{'qc1' |
                            translate}}
                            <span class="text-style-1">{{'qc2' | translate}}</span> {{'qc3' | translate}} <span
                                class="text-style-1">{{'qc4' | translate}} {{'qc5' | translate}}</span>
                        </div>

                    </div>
                    <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 col-xl-10 text-center qoute-copy"
                        *ngIf="language=='hn'">
                        <div class="Be-the-leading-compa">
                            प्राकृतिक गैस की खुदरा बिक्री, वितरण-विपणन में <span class="text-style-1">अग्रणी कंपनी
                                बनना</span>,
                            शहरी गैस वितरण पर केन्द्रित होने के साथ ग्राहकों की देखभाल,
                            <span class="text-style-1">सभी हितधारकों के लिए मूल्य निर्माण और पर्यावरणीय जिम्मेदारी के
                                लिए
                                प्रतिबद्ध होना ।</span>

                        </div>

                    </div>

                    <div class="col-md-2">
                    </div>

                </div>
                <br><br>

                <div class="row mt-5">
                    <div class="col-xs-12 col-sm-4 col-md- 4 col-lg-4 col-xl-4 gas-card" data-aos="fade-up"
                        data-aos-once="true" data-aos-easing="linear" data-aos-duration="1500">
                        <mat-card class="natural-gas" routerLink="/products/cng">
                            <img src="./../../../assets/home/CNG.svg" class="natural-gas-image"
                                alt="Compressed Natural Gas CNG">
                            <mat-card-content>
                                <p class="Piped-Natural-Gas">{{'cng' | translate}}</p>
                            </mat-card-content>
                        </mat-card>
                    </div>

                    <div class="col-xs-12 col-sm-4 col-md- 4 col-lg-4 col-xl-4 gas-card" data-aos="fade-up"
                        data-aos-once="true" data-aos-easing="linear" data-aos-duration="1500">
                        <mat-card class="natural-gas" routerLink="/products/png">
                            <img src="./../../../assets/home/PNG.svg" class="natural-gas-image"
                                alt="Domestic Piped Natural Gas">
                            <mat-card-content>
                                <p class="Piped-Natural-Gas ">{{'png' | translate}}</p>
                                <!-- <p class="Industries-Commerc ">{{'png-dp' | translate}}</p> -->
                            </mat-card-content>
                        </mat-card>
                    </div>

                    <div class="col-xs-12 col-sm-4 col-md- 4 col-lg-4 col-xl-4 gas-card" data-aos="fade-up"
                        data-aos-once="true" data-aos-easing="linear" data-aos-duration="1500">
                        <mat-card class="natural-gas" routerLink="/products/industrial-png">
                            <img src="./../../../assets/home/IC-PNG.svg" class="natural-gas-image" alt="Industries & Commercial
                            Piped Natural Gas">
                            <mat-card-content>
                                <p class="Piped-Natural-Gas ">{{'ic-png' | translate}}</p>
                                <!-- <p class="Industries-Commerc ">{{'png-ic' | translate}}</p> -->
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>

            </div>



        </div>
        <div class="col-md-1"></div>
    </div>




</div>

<!-- Third Fold -->

<div class="container-fluid third_fold">

    <div class="row" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
        <div class="col-md-1"></div>
        <div class="col-md-10">

            <div class="container-fluid">
                <div class="row mt-2">
                    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                        <p class="Notices">
                            <img src="./../../../assets/carousel/leaf.svg">
                            &nbsp;&nbsp;Expression of Interest
                        </p>
                        <!-- <div class="mt-5"></div> -->
                        <div *ngFor="let item of EOIData">
                            <div class="notice-div ">
                                <ul class="notice-cbg">
                                    <li>
                                        {{item}}
                                    </li>
                                </ul>
                            </div><br>
                        </div>

                        <div class="notice-div">
                            <ul class="notice-cbg">
                                <li>
                                    {{this.EOI1}}
                                </li>
                            </ul>
                        </div><br>

                        <div class="notice-div ">
                            <ul class="notice-cbg">
                                <li>
                                    {{this.EOI2}}
                                </li>
                            </ul>
                        </div><br>

                        <div class="notice-div ">
                            <ul class="notice-cbg">
                                <li>
                                    {{this.EOI3}}
                                </li>
                            </ul>
                        </div><br>

                        <div class="notice-div ">
                            <ul class="notice-cbg">
                                <li>
                                    {{this.EOI4}}
                                </li>
                            </ul>
                        </div>
                        <div routerLink="/eoi/expression-interest" style="padding: 26px;  float: right;">
                            <a class="read-more"> {{'viewall' | translate}} &nbsp;&nbsp;<img
                                    src="./../../../assets/carousel/right.svg"></a>
                        </div>
                    </div>

                    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                        <p class="Notices "> <img src="./../../../assets/carousel/leaf.svg">&nbsp;&nbsp;{{'updates'
                            |
                            translate}}</p>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 update-press-release"
                            *ngFor="let press of pressReleaseNotesUpdated" style="margin-bottom: 5px;"
                            (click)="pressReleaseEvent(press)">
                            <img src="{{press.img}}" class="updates-inaguration ">
                            <p class="GAIL-Gas-Bengaluru-i ">{{press.note}}</p>
                            <p class="updates-inaguration-date ">{{press.dt}}</p>
                        </div>

                        <div style="width:90%;">
                            <div class="vieww" (click)="pressReleaseEvent('more')">
                                <span>
                                    <a class="read-more"> {{'viewall' | translate}} &nbsp;&nbsp;<img
                                            src="./../../../assets/carousel/right.svg"></a>
                                </span>
                            </div>
                        </div>

                    </div>

                    <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 reports-column">
                        <p class="Notices"> <img src="./../../../assets/carousel/leaf.svg">&nbsp;&nbsp;{{'rep-publ'
                            |
                            translate}}</p>
                        <div class="row notice-board">
                            <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4 reports-column">
                                <img src="./../../../assets/carousel/carouselMenu/report.png "
                                    class="report-publications">
                            </div>
                            <div class="col-sm 12 col-md-12 col-lg-8 col-xl-8">
                                <a class="annual-Reports"
                                    routerLink="/reports-publications/annual-reports">&#8594;&nbsp;&nbsp;{{'anulReport'
                                    |
                                    translate}}</a><br>
                                <a class="annual-Reports"
                                    routerLink="/reports-publications/brochure">&#8594;&nbsp;&nbsp;{{'crptBrchr' |
                                    translate}}</a><br>
                                <a class="annual-Reports"
                                    routerLink="/reports-publications/policies">&#8594;&nbsp;&nbsp;{{'policyGuideliness'
                                    |
                                    translate}}</a>
                            </div>
                        </div>
                        <br>
                        <br>

                        <div class="row notice-board">
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 reports-column">
                                <img src="./../../../assets/carousel/saving.svg" class="savings-calculator-inreport">
                                <div class="savings-Calculator">
                                    {{'snvgCal' | translate}}<br> {{'cal' | translate}}
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-8">
                                <a class="annual-Reports" routerLink="/home">&#8594;&nbsp;&nbsp;{{'transportSegment'
                                    |
                                    translate}}</a><br>
                                <a class="annual-Reports" routerLink="/home">&#8594;&nbsp;&nbsp;{{'df' |
                                    translate}}</a><br>
                                <a class="annual-Reports" routerLink="/home">&#8594;&nbsp;&nbsp;{{'if' |
                                    translate}}</a><br>
                            </div>
                        </div>

                        <br>
                        <br>

                        <!-- <div class="row" style="cursor: pointer;">
                                notice-board
                                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 image-head">
                                    <img src="./../../../assets/home/cngScheme.svg" class="cngScheme-img"
                                        routerLink="/cngScheme">
                                </div>
                            </div> -->

                    </div>
                </div>
            </div>



        </div>
        <div class="col-md-1"></div>
    </div>

</div>

<!-- Fourth Fold -->
<div class="container-fluid fourth-container">

    <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-10">

            <div class="container-fluid">
                <!-- <div class="row fourth-fold" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1500">
                    <div class="col-md-2 text-center">
                        <img src="./../../../assets/carousel/ports.svg" style="width: 120px;">
                    </div>
                    <div class="col-md-6 text-left">
                        <span class="text1">{{'gailtree1' | translate}}</span><br>
                        <span class="text2">{{'gailtree2' | translate}}</span>
                        <span class="text3">{{'gailtree4' | translate}}</span><br>
                        <span class="text4">{{'gailtree3' | translate}}</span>
                    </div>
                    <div class="col-md-4 text-center gail-tree">
                        <img src="./../../../assets/home/Gail-tree.png">
                    </div>
        
                   
                </div> -->


                
                <div class="bg-main" style="width: auto; height: auto;">
                    <img src="../../../assets/promotionpdf/promotionalBanner.jpeg"
                        style="width: 100%; height: 100%; cursor: pointer;" routerLink="/cng-promotional">
                </div>
            </div>



        </div>
        <div class="col-md-1"></div>
    </div>

</div>



<!-- Sixth fold -->

<div class="container-fluid sixth-fold">

    <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-10">

            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-4" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1000">
                        <div class="card">
                            <img class="card-img-top" src="./../../../assets/carousel/cng-pipe.png" alt="Bologna">
                            <div class="card-body">
                                <h4 class="card-subtitle mb-2 text-muted">{{'cng-indust' | translate}}</h4>
                                <div class="text-right">
                                    <h5><a class="enquire"
                                            href="https://gailebank.gail.co.in:4431/Natural_Gas_for_Industries/(S(fkgm4ae2oaw4kovqnroh44e0))/apply_form.aspx"
                                            target="blank"> {{'enq-nw' | translate}} &nbsp;&nbsp;<img
                                                src="./../../../assets/carousel/right-gas.svg"></a></h5>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="col-md-4" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1300">
                        <div class="card">
                            <img class="card-img-top" src="./../../../assets/carousel/cng-pipe1.png" alt="Bologna">
                            <div class="card-body">
                                <h4 class="card-subtitle mb-2 text-muted">{{'cng-outlt' | translate}}</h4>
                                <div class="text-right">
                                    <span *ngIf="language == 'en'">
                                    </span>
                                    <h5 style="margin-top: -3px;"><a class="enquire" (click)="cngOutletRoute('four')">
                                            {{'kn-mr' | translate}} &nbsp;&nbsp;<img
                                                src="./../../../assets/carousel/right-gas.svg"></a></h5>

                                    <!-- routerLink="/products/cng" -->
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4" data-aos="fade-up" data-aos-easing="linear" data-aos-duration="1600">
                        <div class="card">
                            <img class="card-img-top" src="./../../../assets/carousel/png-pipe.png" alt="Bologna">
                            <div class="card-body">
                                <h4 class="card-subtitle mb-2 text-muted">{{'png-kitcn' | translate}}</h4>
                                <div class="text-right">
                                    <h5><a class="enquire" href="/products/application-procedure"> {{'kn-mr' |
                                            translate}}
                                            &nbsp;&nbsp;<img src="./../../../assets/carousel/right-gas.svg"></a></h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </div>
        <div class="col-md-1"></div>
    </div>



</div>


<!-- Seven fold -->

<div class="container-fluid seven-fold">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-1"></div>
            <div class="col-md-10">
                <p class="Notices"> <img src="./../../../assets/carousel/leaf.svg">&nbsp;&nbsp; {{'associates' |
                    translate}}</p>
                <div class="row">
                    <div class="col-md-12 Surpassing-Boundarie">
                        <h2>{{'srps-bnd' | translate}}/{{'cnp' | translate}}</h2>
                        <!-- <h2 class="text-style-1">{{'cnp' | translate}}</h2> -->
                    </div>
                    <div class="col-md-12" data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1300">
                        <div class="row"
                            style="display: flex;flex-direction: row;align-items: center;justify-content: center;">
                            <div class="col-md">
                                <a href="http://www.apgdc.in" target="_blank" class="associates-href">
                                    <img src="./../../../assets/home/gail-associates/apgdc.png" class="logo-padding"
                                        alt="apgdc-logo">
                                </a>
                            </div>
                            <div class="col-md">
                                <a href="https://www.goanaturalgas.com/" target="_blank" class="associates-href">
                                    <img src="./../../../assets/home/gail-associates/goa-natural-gas.png"
                                        class="logo-padding" alt="Goa Gas limited">
                                </a>
                            </div>
                            <div class="col-md">
                                <a href="https://www.hngpl.in/" target="_blank" class="associates-href">
                                    <img src="./../../../assets/home/gail-associates/hng-logo.png" class="logo-padding"
                                        alt="HNG Logo">
                                </a>
                            </div>
                            <div class="col-md">
                                <a href="http://pbgpl.com/about" target="_blank" class="associates-href">
                                    <img src="./../../../assets/home/gail-associates/pbg-logo.png" class="logo-padding"
                                        alt="PBG Logo">
                                </a>
                            </div>
                            <div class="col-md">
                                <img src="./../../../assets/home/gail-associates/rsgl-gail-gas.png "
                                    class="logo-padding" alt="RSGL Gail Gas">
                            </div>
                            <div class="col-md">
                                <a href="https://www.vgl.co.in/" target="blank" class="associates-href">
                                    <img src="./../../../assets/home/gail-associates/vgl-logo.png" class="logo-padding"
                                        alt="Vadodara Gail Gas Limited">
                                </a>
                            </div>
                        </div>
                        <!-- <img src="./../../../assets/home/gail-associates/kerala-gail-gas.png" class="logo-padding"
                            alt="Kerala Gail Gas"> -->

                    </div>
                </div>




            </div>
            <div class="col-md-1"></div>
        </div>

    </div>
</div>